import React from 'react'
import Markdown from 'markdown-to-jsx'

const Col = ({ children }) => (
  <div className={'col-md-12 col-lg-6'}>
    <div className="box">{children}</div>
  </div>
)

export default ({ columns }) => (
  <div className="row">
    {columns.map((column, index) => (
      <Col key={`column-${index}`}>
        <Markdown children={column.text} />
      </Col>
    ))}
  </div>
)
