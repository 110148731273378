import React from 'react'

import Project from './Project'

export default ({ projects }) => (
  <div>
    {projects.map((project, index) => (
      <Project key={`project-${index}`} {...project} />
    ))}
  </div>
)
