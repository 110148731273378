import React from 'react'
import Markdown from 'markdown-to-jsx'

import imagePath from '../../utils/image-path'
import Testimonial from '../Testimonial'

export default ({ text, image, services, testimonial }) => (
  <div className="project row">
    <div className={'project--text col-sm-12'}>
      {image && (
        <img src={imagePath(image)} alt={``} className="project--image" />
      )}
      <Markdown children={text || ''} />
    </div>
    <div className={'col-md-12 col-lg-6'}>
      <div className="project--services callout">
        <Markdown children={services || ''} />
      </div>
    </div>
    <div className={'col-md-12 col-lg-6'}>
      <Testimonial {...testimonial} />
    </div>
  </div>
)
