import React from 'react'

import Testimonial from './Testimonial'

export default ({ testimonials }) => (
  <div className="row">
    {testimonials.map((testimonial, index) => (
      <div className="col-md-6 col-sm-12 mb-5">
        <Testimonial key={`testimonial-${index}`} {...testimonial} />
      </div>
    ))}
  </div>
)
