import React from 'react'
import imagePath from '../../utils/image-path'

export default ({ text, image, name, position }) => (
  <div className="testimonial d-flex flex-column justify-content-center">
    <i className="fa fa-quote-left testimonial__quote" />
    <blockquote>{text}</blockquote>
    <div className="testimonial__author d-flex align-items-center">
      <img className="rounded-circle" alt={name} src={imagePath(image)} />
      <div className="ml-3">
        <div className="client">{name}</div>
        <div className="position">{position}</div>
      </div>
    </div>
  </div>
)
