import React, { Fragment } from 'react'
import NetlifyForm from 'react-netlify-form'
import { navigate } from 'gatsby'

export default ({ children }) => (
  <div className="contact-from row">
    <div className={'col-md-12 col-lg-6'}>{children}</div>
    <div className={'col-md-12 col-lg-6'}>
      <NetlifyForm onSuccess={() => navigate('/contact/thank-you/')}>
        {({ loading, error, success }) => (
          <Fragment>
            {loading && <div>Loading...</div>}
            {error && <div>Your information was not sent.</div>}
            {!loading && !success && (
              <div>
                <div hidden>
                  <label>
                    <input name="bot-field" />
                  </label>
                </div>
                <div className="form-group">
                  <label htmlFor={'name'}>Your name</label>
                  <input
                    className="form-control"
                    type={'text'}
                    name={'name'}
                    id={'name'}
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={'email'}>Email</label>
                  <input
                    className="form-control"
                    type={'email'}
                    name={'email'}
                    id={'email'}
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <label className="label" htmlFor={'message'}>
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    name={'message'}
                    id={'message'}
                    required={true}
                    rows={5}
                  />
                </div>
                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Send
                  </button>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </NetlifyForm>
    </div>
  </div>
)
