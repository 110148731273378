import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'markdown-to-jsx'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'
import Columns from '../components/Columns'
import Testimonials from '../components/Testimonials'
import Projects from '../components/Projects'

export const PageTemplate = ({ title, hero, body, sections }) => {
  return (
    <Fragment>
      {hero && (hero.text || hero.background) && <Hero {...hero} />}
      {body && (
        <Section>
          <Markdown className="content" children={body} />
        </Section>
      )}
      {(sections || []).map((section, index) => {
        if (section.type === 'text') {
          return (
            <Section key={`section-${index}`} {...section}>
              <Markdown className="content" children={section.text} />
            </Section>
          )
        }
        if (section.type === 'testimonials') {
          return (
            <Section key={`section-${index}`} {...section}>
              {section.text && (
                <Markdown className="content" children={section.text} />
              )}
              <Testimonials testimonials={section.testimonials} />
            </Section>
          )
        }
        if (section.type === 'projects') {
          return (
            <Section key={`section-${index}`} {...section}>
              {section.text && (
                <Markdown className="content" children={section.text} />
              )}
              <Projects projects={section.projects} />
            </Section>
          )
        }
        if (section.type === 'columns') {
          return (
            <Section key={`section-${index}`} {...section}>
              {section.text && (
                <Markdown className="content" children={section.text} />
              )}
              <Columns columns={section.columns} />
            </Section>
          )
        }
        if (section.type === 'contact') {
          return (
            <Section key={`section-${index}`} {...section}>
              <ContactForm>
                {section.text && (
                  <Markdown className="content" children={section.text} />
                )}
              </ContactForm>
            </Section>
          )
        } else {
          return (
            <Section key={`section-undefined`}>
              {JSON.stringify(section)}
            </Section>
          )
        }
      })}
    </Fragment>
  )
}

PageTemplate.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string.isRequired,
  canonical_link: PropTypes.string,
  description: PropTypes.string,
  hero: PropTypes.object,
  sections: PropTypes.array,
}

const Page = ({ pageContext: { page } }) => (
  <Layout {...page}>
    <PageTemplate {...page} />
  </Layout>
)

export default Page
